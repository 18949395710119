<template>
  <div class="select-outer">
    <van-nav-bar
      title="详情"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
   <div class="con-outer">
    <!-- :node-id="selectInstance.nodeId"
        :instance-id="selectInstance.instanceId" -->
     <instance-preview
     
        :node-id="nodeId"
        :instance-id="instanceId"
        @handler-after="handlerAfter"
      ></instance-preview>
   </div>
  </div>
</template>

<script>
import InstancePreview from "../approval/ProcessInstancePreview";
export default {
  data() {
    return {
      valueSearch:"",
      nodeId:"node_490826256372",
      instanceId:"wf202404221502281700960"
    };
  },
  components: { InstancePreview },
  methods: {
      handlerAfter() {
        console.log("操作完?")
      // this.processVisible = false;
      // this.getTodoList();
    },
    onSearch(value) {},
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.select-outer{
   /deep/ .van-nav-bar .van-icon {
    color: #333;
  }
  /deep/ .van-nav-bar__text {
    color: #333;
  }
   /deep/ .van-search {
    padding: 0;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  .filter-top{
    display: flex;
    justify-content: space-between;
    .search-block{
      width: 50%;
    }
  }
}
</style>
